<template>
    <div>
        <v-divider class="mx-0" />
        <v-list-item @click="openResource">
            <v-list-item-content class="mr-3">
                <v-row>
                    <v-col cols="12" class="px-3">
                        <v-list-item-title>{{
                            notification.message
                        }}</v-list-item-title>
                        <div style="font-size: 13px" class="mt-1">
                            {{ formatDate(notification.createdOn.seconds) }}
                        </div>
                    </v-col>
                </v-row>
            </v-list-item-content>

            <v-list-item-action class="mx-0">
                <v-btn
                    v-if="notification.reference != 'pFDh25EcR74EMiuypq8M'"
                    icon
                    @click.stop="changeReadStatus(notification)"
                    :loading="readLoading"
                >
                    <v-icon :color="notification.read ? 'primary' : ''">
                        {{
                            notification.read
                                ? 'mdi-check-circle'
                                : 'mdi-check-circle-outline'
                        }}
                    </v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
    </div>
</template>

<script>
import API from '@/services/api'
import moment from 'moment'
import { mapState, mapActions } from 'vuex'

export default {
    name: 'NotificationsBell',
    props: {
        notification: {
            type: Object,
            default: () => ({}),
        },
        notificationDocs: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            readLoading: false,
            deleteLoading: false,
        }
    },
    computed: {
        ...mapState(['settings']),
    },
    methods: {
        ...mapActions(['setNotificationResource']),
        async changeReadStatus() {
            try {
                this.readLoading = true
                // remove a notification
                await API.checkAlertNotification({
                    resourceId: this.notification.resourceId,
                    notificationId: this.notification.reference,
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.readLoading = false
            }
        },

        async deleteNotification() {
            try {
                this.deleteLoading = true
                // remove a notification
                await API.checkAlertNotification({
                    resourceId: this.notification.resourceId,
                    notificationId: this.notification.reference,
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.deleteLoading = false
            }
        },

        formatDate(seconds) {
            return moment.unix(seconds).format('DD-MM-YY h:mm a')
        },

        async openResource() {
            const path = this.notification.path
            const currentRoute = this.$route.path
            const resourceId = this.notification.resourceId
            if (path == '/chat-quote') {
                const {
                    data: { quotes },
                } = await API.getQuote(resourceId)
                this.$emit('openQuote', quotes)
            } else if (currentRoute !== path) {
                this.$router.push({
                    path: `${path}?resourceId=${resourceId}`,
                })
            } else {
                this.setNotificationResource(resourceId)
            }
            // remove a notification
            if (this.notification.reference == 'pFDh25EcR74EMiuypq8M') {
                await API.checkAlertNotification({
                    resourceId: this.notification.resourceId,
                    notificationId: this.notification.reference,
                })
            }
        },
    },
}
</script>
